import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Seo from '@gtg/components/seo'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ArticleLayout from '../components/articleLayout'
import Category from '../components/category'
import GlobalWrapper from '../components/globalWrapper'
import Subtitle from '../components/subtitle'
import Img2Src from '../images/articles/highquality/2.jpg'
import Img3Src from '../images/articles/highquality/3.png'
import { color } from '../styles/theme'

const BottomBox = styled(Box)`
  background: ${color.coal50};
  border-radius: 10px;
  box-shadow: 0px 10px 40px #e3ddd4;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const BoxImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 500px;
`

const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Lenzbox Wissen, Ratgeber</Category>
            <hr />
            <h1>Hochwertiger Kontaktlinsenbehälter – die Lenzbox</h1>
            <Subtitle>Das neue Zuhause für Deine Kontaktlinsen</Subtitle>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img2Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <p>
              Wir stehen für Passion und Qualität. Unser Anspruch ist es, dass
              Aufbewahrung und Pflege von Kontaktlinsen so einfach wie nie zuvor
              werden.
            </p>
            <p>
              Sicherheit und Funktionalität sind für uns dabei von größter
              Bedeutung. Denn die Lenzbox ist ein Medizinprodukt, das mit den
              Kontaktlinsen für deine Augen in Berührung kommt. Design und
              Funktionalität gehen bei uns Hand in Hand. Das Herzstück unserer
              Innovation wird daher von einem außergewöhnlichen Design
              abgerundet.
            </p>
            <p>
              Für höchste Qualität und Funktionalität erfolgen Entwicklung und
              Produktion ausschließlich in Europa. So haben wir
              von Beginn an sowohl mit Forschern renommierter Institute, mit
              Augenärzten, mit Experten von Kontaktlinsenherstellern sowie mit
              erfahrenen mittelständischen
              Produktionsunternehmen partnerschaftlich zusammengearbeitet. 
            </p>
            <p>
              Unser Ziel: die höchstmögliche Wertigkeit deines zukünftigen
              Kontaktlinsenbehälters!
            </p>
            <h2>Kontaktlinsen aufbewahren</h2>
            <p>
              Ob beim Sport, auf Reisen oder im alltäglichen Leben: deine
              Kontaktlinsen begleiten dich überall. Wenn du sie dann nach dem
              Joggen oder abends wieder aus den Augen nimmst, wird das
              Kontaktlinsenaufbewahren relevant. Hier konntest du dich bisher
              nur zwischen wenigen Alternativen entscheiden. So oder so, du
              musst dich um deine Kontaktlinsen kümmern, sie pflegen und
              reinigen. Nach der Pflege kommt dann meist ein einfacher
              Kontaktlinsenbehälter zum Einsatz.
            </p>
            <h2>Kontaktlinsenbehälter</h2>
            <p>
              Das richtige Reinigen und Aufbewahren deiner Kontaktlinsen in
              einem Kontaktlinsenbehälter ist dabei ein wichtiger Bestandteil
              bei der Kontaktlinsenpflege. Dabei ist es besonders wichtig, dass
              du deine Kontaktlinsen mit der richtigen Hygiene behandelst. Achte
              dabei auf alle essenziellen Schritte der Kontaktlinsenpflege.
              Diese haben wir auch nochmal ausführlich in einem{' '}
              <Link to="/kontaktlinsen-pflegen"> eigenen Artikel für dich</Link>{' '}
              zusammengefasst. Wichtig ist, dass du bei der Kontaktlinsenpflege
              gründlich vorgehst. Wir kennen das Problem, dass häufig keine Zeit
              und Lust vorhanden ist, um die Kontaktlinsen richtig zu reinigen.
              Wenn es um die Gesundheit deiner Augen geht, solltest du jedoch
              vorsichtig sein. Wenn du deine Kontaktlinsen nicht richtig pflegst
              und aufbewahrst, kann dies zu Infektionen im Auge führen.
            </p>
            <p>
              Nach dem Reinigen deiner Kontaktlinsen solltest du deine
              Kontaktlinsen in Aufbewahrungslösung geben. Dies ist zumeist eine
              Kochsalzlösung oder eine All-in-one-Lösung. Diese schützen deine
              Kontaktlinsen vor dem Austrocknen und helfen bei der Regeneration.
              Bei den meisten Herstellern empfiehlt es sich, die Kontaktlinsen
              mindestens 6 Stunden bzw. über Nacht im Kontaktlinsenbehälter
              aufzubewahren. Die Kontaktlinsenaufbewahrungsflüssigkeit ist nach
              jedem Gebrauch zu entsorgen. Du solltest also auf keinen Fall die
              benutzte Kontaktlinsenflüssigkeit „wiederverwenden“, da es in
              diesem Fall zu Infektionen in deinem Auge kommen kann.
            </p>
            <p>
              Wenn du deine Kontaktlinsen erst nach ein paar Tagen wieder
              benötigst, kannst du sie auch länger in deinem
              Kontaktlinsenbehälter lassen. Hierbei solltest du darauf achten,
              dass die Kontaktlinsenflüssigkeit auf Dauer verdunsten kann und
              die Pflege dann nicht mehr gewährleistet ist. Du solltest sie also
              am besten nicht länger als eine Woche in dem Kontaktlinsenbehälter
              aufbewahren. Da es um die Gesundheit deiner Augen geht, solltest
              du deine Kontaktlinsen im Zweifelsfall immer gegen neue
              austauschen. Neben deinen Kontaktlinsen ist auch die richtige
              Hygiene bei konventionellen Kontaktlinsenbehältern zu beachten.
            </p>
            <p>
              Wenn du deine Kontaktlinsen wieder einsetzen möchtest, solltest du
              sie noch einmal mit Kochsalzlösung abspülen. Und auch deinen
              Kontaktlinsenbehälter solltest du nach der Benutzung einmal mit
              Reinigungs- und Kochsalzlösung durchspülen, damit du beim nächsten
              Mal wieder deine Kontaktlinsen sauber aufbewahren kannst. Im
              Anschluss sollte der Kontaktlinsenbehälter über den Tag
              offenbleiben, damit er trocknen kann. Zusätzlich sollte der
              Kontaktlinsenbehälter einmal in der Woche mit der
              Desinfektionslösung deiner Kontaktlinsen gefüllt werden und im
              Anschluss auf den Deckel gedreht werden, damit auch dieser
              gereinigt wird. Einige konventionelle Kontaktlinsenbehälter
              offenbaren schon hier ihre Schwächen, da sie oft nicht gut
              produziert sind und daher nicht dicht verschlossen werden können.
              Nach einem Monat der Benutzung sollten konventionelle
              Kontaktlinsenbehälter entsorgt werden.
            </p>
            <p>
              Die aufwendige Pflege von Kontaktlinsen hat uns als Gründer und
              Ärzte schon immer geärgert. Die Hygiene sollte aus unserer Sicht
              nie zu kurz kommen. Daher haben wir die Lenzbox entwickelt. Sie
              kombiniert Pflege, Reinigung und Aufbewahrung deiner
              Kontaktlinsen. Wir möchten den Prozess so einfach wie möglich
              gestalten und dabei die notwendige Hygiene nicht außer Acht zu
              lassen. Daher brauchst du schon bald nur noch einen Knopf drücken.
            </p>
            <h2>Forschung und Entwicklung</h2>
            <p>
              Es ist viel Arbeit in die Forschung und Entwicklung der Lenzbox
              als Kontaktlinsenbehälter und Pflegesystem geflossen. Heute sind
              wir stolz, dass wir das Kontaktlinsenaufbewahren revolutionieren.
            </p>
            <p>
              2015 wurde das erste gemeinsame Projekt für die erfolgreiche
              Entwicklung der Lenzbox als Kontaktlinsenbehälter gestartet.
              Unsere Kooperation und unser Engagement haben wir dabei von Beginn
              an mit renommierten Forschungseinrichtungen koordiniert. Der Markt
              für Kontaktlinsenaufbewahrung und Kontaktlinsenbehälter hat sich
              seit über 40 Jahren kaum verändert. Die konventionelle
              Kontaktlinsenaufbewahrung war uns nicht genug. Daher haben wir uns
              an das Projekt Lenzbox gemacht. Die Entwicklung eines durchweg
              neuen Systems zur Pflege und Aufbewahrung der Kontaktlinsen war
              herausfordernd.
            </p>
            <p>
              Der Anspruch an die Lenzbox war dabei schon immer klar definiert.
              Wir wollen eine Lösung, auf die du vertrauen kannst und die den{' '}
              <a
                href="https://eur-lex.europa.eu/eli/reg/2017/745"
                target="_blank"
                rel="noreferrer"
              >
                neuesten europäischen Standard für Medizinprodukte
              </a>{' '}
              erfüllt. Durch die weitergehende Forschung und partnerschaftliche
              Zusammenarbeit mit mittelständischen Unternehmen aus Deutschland
              haben wir es geschafft, dass unsere Innovation des
              Kontaktlinsenbehälters bedenkenlos das alltägliche Leben ihrer
              Nutzer verbessern wird.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Center>
            <BoxImg src={Img3Src} />
          </Center>
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Hochwertige Produktion</h2>
            <p>
              Nach über 5 Jahre Forschung und Entwicklung haben wir es
              geschafft. Unsere patentierte Lösung für Kontaktlinsenbehälter der
              Zukunft kann endlich Realität werden.
            </p>
            <p>
              Dabei setzen wir auf enge Partnerschaften und kurze Liefer- und
              Verarbeitungswege. Um die bestmögliche Lösung zu kreieren, haben
              wir uns mit verschiedenen Partnern der Industrie
              zusammengeschlossen. Gemeinsam haben wir langjährige Erfahrungen
              in verschiedenen Bereichen der Produktion von medizinischen
              Produkten.
            </p>
            <p>
              Am Ende der Produktion ist die Innnovation Realität. Denn weniger
              ist in diesem Fall mehr!
            </p>
          </Box>
        </Container>
        <Box px={0} pt={0}>
          <Container wMax="md">
            <BottomBox pxMax="xl" pyMax="lg">
              <h2>Kontaktlinsenbehälter: reinigen einfach gemacht</h2>
              <p>
                Das Pflegen und Reinigen der Kontaktlinsen und
                Kontaktlinsenbehälter sind seit vielen Jahren gleichgeblieben.
                Mit der <Link to="/">Lenzbox</Link> wollen wir das ändern.
                Innnovation heißt dabei Fortschritt auf mehreren Ebenen: Design
                und Nutzerfreundlichkeit gepaart mit Sicherheit in der Hygiene
                und Verringerung von Müll. Die Verbesserung und
                Weiterentwicklung der herkömmlichen Kontaktlinsenpflege und
                Aufbewahrung ist dabei geprägt von Innovationsgeist und Qualität
                „Made in Germany“.
              </p>
              <p>
                Mit der Lenzbox als Kontaktlinsenbehälter wird das in Zukunft
                leichter. Wir haben viel Zeit und Energie in die Innovation
                gesteckt, damit du nur noch einen Knopf drücken brauchst.
              </p>
            </BottomBox>
          </Container>
        </Box>
      </ArticleLayout>
    </GlobalWrapper>
  )
}

export default ArticlePage
