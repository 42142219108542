import React from 'react'
import styled from 'styled-components'
import { color } from '../styles/theme'

const StyledCategory = styled.p`
  font-weight: bold;
  color: ${color.grey500};
  text-align: center;
  margin: 0 0 1rem 0 !important;
`

const Category = props => {
  return <StyledCategory>{props.children}</StyledCategory>
}

export default Category
