import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Nav from '@gtg/components/nav'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from '../styles/theme'
import Footer from './footer'

const BlueBar = styled.div`
  background: ${color.brand500};
  height: 12px;
  width: 100%;
  position: relative;
  z-index: 1;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h1 {
    text-align: center;
    font-size: ${fontSize.f11};
    font-family: serif;
    font-weight: normal;
    margin: 0 0 1rem 0;
    ${media.lg`
      font-size: ${fontSize.f10};
    `}
    ${media.md`
      font-size: ${fontSize.f9};
      margin-bottom: 0.75rem;
    `}
    ${media.sm`
      font-size: ${fontSize.f8};
    `}
    ${media.xs`
      font-size: ${fontSize.f7};
    `}
  }
  h2 {
    /* font-weight: normal; */
    font-size: ${fontSize.f7};
    ${media.lg`
      font-size: ${fontSize.f7};
    `}
    ${media.md`
      font-size: ${fontSize.f6};
    `}
    ${media.xs`
      font-size: ${fontSize.f5};
    `}
  }
  h3 {
    font-size: ${fontSize.f4};
  }
`

const ContentSection = styled.section`
  flex: 1;
`

const StyledBox = styled(Box)`
  background: ${color.coal50};
`

const StyledNav = styled(Nav)`
  height: 104px;
`

const PageLayout = props => {
  return (
    <Flex>
      <BlueBar />
      <StyledBox py={0}>
        <Container wMax="xl">
          <StyledNav
            withLayout={false}
            logoStyle="dark"
            textColor={color.coal900}
          />
        </Container>
      </StyledBox>
      <ContentSection>{props.children}</ContentSection>
      <Footer wMax="xl" />
    </Flex>
  )
}

export default PageLayout
