import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from '../styles/theme'

const StyledSubtitle = styled.p`
  text-align: center;
  color: ${color.grey500};
  font-size: ${fontSize.f6};
  margin: 0 0 1rem 0 !important;
  ${media.lg`
  font-size: ${fontSize.f5};
`}
  ${media.xs`
  font-size: ${fontSize.f4};
`}
`

const Subtitle = props => {
  return <StyledSubtitle>{props.children}</StyledSubtitle>
}

export default Subtitle
